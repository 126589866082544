import { useEffect, useState } from "react";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Modal, Box, Typography, FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// components
import Iconify from "../../components/iconify";
import FormProvider, { RHFCodes, RHFTextField } from "../../components/hook-form";
 
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "src/routes/paths";
import { requestPermission } from "./firebase";
import { fetchLocation } from "src/utils/fetchLocation";
 
// ----------------------------------------------------------------------
 
type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
  uuid: string;
};
 
export default function AuthLoginForm() {
  const { Api } = useAuthContext();
 
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuthContext();
  const navigate = useNavigate();
 
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };
 
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", md: 500 },
    bgcolor: "#ffffff",
    borderRadius: 2,
    p: 4,
  };
 
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
 
  const defaultValues = {
    email: "",
    password: "",
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
    uuid: "",
  };
 
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });
 
  const {
    reset,
    setValue,
    setError,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;
 
  useEffect(() => {
    fetchLocation();
    requestPermission();
  }, []);
 
  const onSubmit = async (data: FormValuesProps) => {
    try {
      const body = {
        password: data.password,
        email: data.email,
        FCM_Token: sessionStorage.getItem("fcm"),
      };
 
      await Api(`admin/admin_login`, "POST", body, "").then((Response: any) => {
        if (Response?.status == 200) {
          if (Response.data.code == 200) {
            setValue("uuid", Response.data.uuid);
            handleOpen();
            // Api("admin/adminDetails", "GET", "", Response.data.data.token).then(
            //   (resp: any) => {
            //     if (resp.status == 200) {
            //       if (resp.data.code == 200) {
            //         login(Response.data.data.token, resp.data.data);
            //       }
            //     }
            //   }
            // );
            enqueueSnackbar(Response.data.message);
          } else {
            enqueueSnackbar(Response.data.message);
          }
        } else {
          enqueueSnackbar("Failed");
        }
      });
      // console.log('===========>',data.email)
    } catch (error) {
      console.error(error);
      reset();
      setError("afterSubmit", {
        ...error,
        message: error.message,
      });
    }
  };
 
 
  const onVerify = async () => {
    setIsLoading(true);
    try {
      const body = {
        email: getValues("email"),
        otp:
          getValues("code1") +
          getValues("code2") +
          getValues("code3") +
          getValues("code4") +
          getValues("code5") +
          getValues("code6"),
        uuid: getValues("uuid"),
        FCM_Token: sessionStorage.getItem("fcm"),
      };
      await Api(`admin/verifyAdminLogin`, "POST", body, "").then(
        (Response: any) => {
          if (Response.status == 200) {
            if (Response.data.code == 200) {
              Api(
                "admin/adminDetails",
                "GET",
                "",
                Response.data.data.token
              ).then((resp: any) => {
                if (resp.status == 200) {
                  if (resp.data.code == 200) {
                    login(Response.data.data.token, resp.data.data);
                    handleClose();
                    setIsLoading(false);
                  }
                }
              });
              enqueueSnackbar(Response.data.message);
            } else {
              throw new Error(Response.data.message);
            }
          } else {
            throw new Error("Server Error");
          }
        }
      );
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
 
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {/* {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>} */}
        <RHFTextField name="email" label="Email address" />
 
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
 
      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link
          color="inherit"
          variant="subtitle2"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(PATH_AUTH.resetpassword)}
        >
          Forgot password?
        </Link>
      </Stack>
 
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{
          mt: 2,
          bgcolor: "text.primary",
          color: (theme) =>
            theme.palette.mode == "light" ? "common.white" : "grey.800",
          "&:hover": {
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode == "light" ? "common.white" : "grey.800",
          },
        }}
      >
        Login
      </LoadingButton>
      <Modal open={open}>
        <Box sx={style}>
          {/* rhfcodes login */}
 
          <Typography variant="h4" textAlign={"center"} sx={{ mb: 2 }}>
            Verify OTP
          </Typography>
 
          <Typography variant="body2" textAlign={"center"} sx={{ mb: 2 }}>
            Enter the OTP sent to your email
          </Typography>
 
          <RHFCodes
            keyName="code"
            inputs={["code1", "code2", "code3", "code4", "code5", "code6"]}
          />
 
          {(!!errors.code1 ||
            !!errors.code2 ||
            !!errors.code3 ||
            !!errors.code4 ||
            !!errors.code5 ||
            !!errors.code6) && (
            <FormHelperText error sx={{ px: 2 }}>
              Code is required
            </FormHelperText>
          )}
          <Stack flexDirection={"row"} justifyContent={"center"} my={2} gap={1}>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={onVerify}
            >
              Verify
            </LoadingButton>
            {!isLoading && (
              <LoadingButton variant="outlined" onClick={handleClose}>
                Close
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </Modal>
    </FormProvider>
  );
}